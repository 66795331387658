var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("holiday-date-picker", {
        attrs: {
          type: "date",
          "picker-options": {
            disabledDate: _vm.disabledDate,
          },
          placeholder: "选择日期",
        },
        model: {
          value: _vm.value1,
          callback: function ($$v) {
            _vm.value1 = $$v
          },
          expression: "value1",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }