<template>
  <div>
    <holiday-date-picker
      v-model="value1"
      type="date"
      :picker-options="{
        disabledDate
      }"
      placeholder="选择日期"
    >
    </holiday-date-picker>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: "2023-12-12",
      finishTime: "2030-01-01 00:00:00", // 限制到某月某日以后不能被选择
      holidays: [], // 后端返回数据
    };
  },
  created() {

  },
  methods: {
    // 定义限制的方法
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
  },
};
</script>

<style>
</style>
