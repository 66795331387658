var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "left-board" },
        [
          _c("div", { staticClass: "logo-wrapper" }, [
            _c("div", { staticClass: "logo" }, [
              _c("img", { attrs: { src: _vm.logo, alt: "logo" } }),
              _vm._v(" Form Generator "),
            ]),
          ]),
          _c("el-scrollbar", { staticClass: "left-scrollbar" }, [
            _c(
              "div",
              { staticClass: "components-list" },
              [
                _c(
                  "div",
                  { staticClass: "components-title" },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "component" } }),
                    _vm._v("输入型组件 "),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "components-draggable",
                    attrs: {
                      list: _vm.inputComponents,
                      group: {
                        name: "componentsGroup",
                        pull: "clone",
                        put: false,
                      },
                      clone: _vm.cloneComponent,
                      draggable: ".components-item",
                      sort: false,
                    },
                    on: { end: _vm.onEnd },
                  },
                  _vm._l(_vm.inputComponents, function (element, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "components-item",
                        on: {
                          click: function ($event) {
                            return _vm.addComponent(element)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "components-body" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": element.tagIcon },
                            }),
                            _vm._v(" " + _vm._s(element.label) + " "),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "components-title" },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "component" } }),
                    _vm._v("选择型组件 "),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "components-draggable",
                    attrs: {
                      list: _vm.selectComponents,
                      group: {
                        name: "componentsGroup",
                        pull: "clone",
                        put: false,
                      },
                      clone: _vm.cloneComponent,
                      draggable: ".components-item",
                      sort: false,
                    },
                    on: { end: _vm.onEnd },
                  },
                  _vm._l(_vm.selectComponents, function (element, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "components-item",
                        on: {
                          click: function ($event) {
                            return _vm.addComponent(element)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "components-body" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": element.tagIcon },
                            }),
                            _vm._v(" " + _vm._s(element.label) + " "),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "components-title" },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "component" } }),
                    _vm._v(" 布局型组件 "),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "components-draggable",
                    attrs: {
                      list: _vm.layoutComponents,
                      group: {
                        name: "componentsGroup",
                        pull: "clone",
                        put: false,
                      },
                      clone: _vm.cloneComponent,
                      draggable: ".components-item",
                      sort: false,
                    },
                    on: { end: _vm.onEnd },
                  },
                  _vm._l(_vm.layoutComponents, function (element, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "components-item",
                        on: {
                          click: function ($event) {
                            return _vm.addComponent(element)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "components-body" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": element.tagIcon },
                            }),
                            _vm._v(" " + _vm._s(element.label) + " "),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center-board" },
        [
          _c(
            "div",
            { staticClass: "action-bar" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "iconfont icon-daoru", type: "text" },
                  on: { click: _vm.download },
                },
                [_vm._v(" 导出vue文件 ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "copy-btn-main",
                  attrs: { icon: "el-icon-document-copy", type: "text" },
                  on: { click: _vm.copy },
                },
                [_vm._v(" 复制代码 ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "delete-btn",
                  attrs: { icon: "iconfont icon-delete", type: "text" },
                  on: { click: _vm.empty },
                },
                [_vm._v(" 清空 ")]
              ),
            ],
            1
          ),
          _c(
            "el-scrollbar",
            { staticClass: "center-scrollbar" },
            [
              _c(
                "el-row",
                {
                  staticClass: "center-board-row",
                  attrs: { gutter: _vm.formConf.gutter },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: _vm.formConf.size,
                        "label-position": _vm.formConf.labelPosition,
                        disabled: _vm.formConf.disabled,
                        "label-width": _vm.formConf.labelWidth + "px",
                      },
                    },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "drawing-board",
                          attrs: {
                            list: _vm.drawingList,
                            animation: 340,
                            group: "componentsGroup",
                          },
                        },
                        _vm._l(_vm.drawingList, function (element, index) {
                          return _c("draggable-item", {
                            key: element.renderKey,
                            attrs: {
                              "drawing-list": _vm.drawingList,
                              element: element,
                              index: index,
                              "active-id": _vm.activeId,
                              "form-conf": _vm.formConf,
                            },
                            on: {
                              activeItem: _vm.activeFormItem,
                              copyItem: _vm.drawingItemCopy,
                              deleteItem: _vm.drawingItemDelete,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.drawingList.length,
                              expression: "!drawingList.length",
                            },
                          ],
                          staticClass: "empty-info",
                        },
                        [_vm._v(" 从左侧拖入或点选组件进行表单设计 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("right-panel", {
        attrs: {
          "active-data": _vm.activeData,
          "form-conf": _vm.formConf,
          "show-field": !!_vm.drawingList.length,
        },
        on: { "tag-change": _vm.tagChange },
      }),
      _c("code-type-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          title: "选择生成类型",
          "show-file-name": _vm.showFileName,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          confirm: _vm.generate,
        },
      }),
      _c("input", { attrs: { id: "copyNode", type: "hidden" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }